import React, { Component } from "react";
import { Jumbotron } from "react-bootstrap";
import BuyMeACoffe from "./BuyMeACoffee";

class About extends Component {
	
	render() {
		return (
			<Jumbotron>
				<h1>Mortgage Simulator</h1>
				<p>
					Developed by <a target="_blank" rel="noopener noreferrer" href="https://paolo.sangregorio.dev">Paolo Sangregorio</a>
				</p>
				<BuyMeACoffe />
			</Jumbotron>
		);
	}

}

export default About;