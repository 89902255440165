import React, { Component } from "react";
import { connect } from "react-redux";
import { FieldArray } from "formik";
import { Table, Form, Button, Badge, Col } from "react-bootstrap";

class InterestRatesTable extends Component {

  render() {
    const { values, handleChange } = this.props;
    const FormRow = Form.Row;
    return (
      <FieldArray
        name="rates"
        render={arrayHelpers => (
          <Table bordered hover>
            <thead>
              <tr>
                <th style={{width: '120px'}}>From month</th>
                <th>Interest rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '18px' }}>1</td>
                <td>
                  <Form.Control type="number" placeholder="e.g. 2.99" style={{width: '160px'}} name={`rates[0].rate`} value={values.rates[0].rate} onChange={handleChange}></Form.Control>
                </td>
              </tr>
              {values.rates.map((rate, index) => {
                if (index === 0) { return false }
                return (
                <tr key={`row-${index}`}>
                  <td>
                    <Form.Control type="number" name={`rates[${index}].month`} value={rate.month} placeholder="e.g. 15" onChange={handleChange} />
                  </td>
                  <td>
                    <FormRow>
                      <Col>
                        <Form.Control type="number" name={`rates[${index}].rate`} value={rate.rate} placeholder="e.g. 2.99" style={{width: '160px'}} onChange={handleChange} />
                      </Col>
                      <Col>
                        <Badge style={{ marginTop: "10px", cursor: "pointer" }} variant="danger" onClick={(e) => arrayHelpers.remove(index)}>X</Badge>
                      </Col>
                    </FormRow>
                  </td>
                </tr>
              )})}
              <tr>
                <td colSpan={2}>
                  <Button variant="outline-secondary" block onClick={() => arrayHelpers.push({ rate: null, month: null })}>Add more</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    
  }
};

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(InterestRatesTable);