import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from 'yup';
import { setSimulationResult, setCurrency } from "../actions";
import * as firebase from "firebase";
import { Form, Button, Col, Table, Row } from "react-bootstrap";
import InterestRatesTable from "./InterestRatesTable";
import OverpaymentsTable from "./OverpaymentsTable";
import { currencies } from "../helpers/currencies";

class CalculatorForm extends Component {

	constructor(...args) {
		super(...args);
		this.state = { validated: true };
	}

	simulateMortgage(values, { setSubmitting }, _this) {
		console.log(values);

		console.log('before firebase functions');
		var simulateMortgage = firebase.functions().httpsCallable("simulateMortgage");

		console.log('done');
		console.log(simulateMortgage);

		const { rates, overpayments, principal } = values;

		const mortgageLength = values.mortgageLength * 12;
		const monthlyRates = Array(mortgageLength);
		rates.forEach((rateDetails) => {
			monthlyRates.fill(parseFloat(rateDetails.rate), rateDetails.month-1);
		});
		const monthlyOverpayments = Array(mortgageLength).fill(0);
		overpayments.forEach((overpaymentDetails) => {
			monthlyOverpayments.fill(parseFloat(overpaymentDetails.amount), overpaymentDetails.month-1);
		});

		simulateMortgage({ loan: principal, rates: monthlyRates, months: mortgageLength, overpayments: monthlyOverpayments, lump_sums: Array(420).fill(0) }).then(function(result) {
			_this.props.setSimulationResult(result.data);
			setSubmitting(false);
		});
	}

	getMortgageYearsOptions() {
		const years_options = [];
		for (var i = 1; i <= 35; i++) {
			years_options.push(<option key={`option-${i}`}>{i}</option>);
		}
		return years_options;
	}

	getCurrencyOptions() {
		const currency_options = [];
		for (var i = 1; i <= currencies.length; i++) {
			currency_options.push(<option key={`currency-option-${i}`}>{currencies[i]}</option>);
		}
		return currency_options;
	}

	validate(values) {
		let errors = {};
	
		return errors;
	}

	render() {
		const { currency } = this.props;
		const { validated } = this.state;
		
		const initialValues = {
			principal: '',
			mortgageLength: 10,
			overpayments: [],
			rates: [{ month: 1, rate: '' }]
		}

		return (
			<Formik validate={this.validate}
				onSubmit={(values, formikActions) => this.simulateMortgage(values, formikActions, this)}
				initialValues={initialValues}
			>
			{({
				handleSubmit,
				handleChange,
				handleBlur,
				values,
				touched,
				isValid,
				errors,
				isSubmitting
			}) => (
				<Form noValidate validated={false}>
					<Form.Row>
						<Form.Group as={Col} controlId="principal">
							<Form.Label>Principal</Form.Label>
							<Form.Control isValid={false} name="principal" value={values.principal} type="number" placeholder="The mortgage principal (e.g. 100000)" onChange={handleChange} />
							<Form.Control.Feedback type="invalid">Please enter your mortgage amount here</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col} md={3} controlId="currency">
							<Form.Label>Currency</Form.Label>
							<Form.Control value={currency} as="select" onChange={(e) => this.props.setCurrency(e.target.value)}>
								{this.getCurrencyOptions()}
							</Form.Control>
						</Form.Group>
					</Form.Row>
				  <Form.Group controlId="mortgageLength">
				    <Form.Label>Mortgage length</Form.Label>
				    <Form.Control name="mortgageLength" value={values.mortgageLength} as="select" onChange={handleChange}>
				      {this.getMortgageYearsOptions()}
				    </Form.Control>
				  </Form.Group>
					<InterestRatesTable handleChange={handleChange} values={values} />
					<OverpaymentsTable handleChange={handleChange} values={values} />
				  <Button variant="primary" disabled={isSubmitting} onClick={handleSubmit}>
						{isSubmitting ? 'Loading…' : 'Simulate!'}
					</Button>
				</Form>
			)}
			</Formik>
		)
	}
}

const mapStateToProps = state => {
	const { currency } = state.config;
	return {
		currency
	}
}

const mapDispatchToProps = {
	setSimulationResult, setCurrency
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorForm);