import { SET_SIMULATION_RESULT } from "../actionTypes"

const initialState = {
	totalInterest: 0,
	totalOverpayment: 0,
	totalLumpSums: 0,
	monthlyRows: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_SIMULATION_RESULT: {
			const { totalInterest, totalOverpayment, totalLumpSums, monthlyRows } = action.payload;
			return {
				totalInterest,
				totalOverpayment,
				totalLumpSums,
				monthlyRows
			};
		}
		default:
			return state;
	}
}