import React, { Component } from "react";
import * as fb from "./config/firebase"; // Keep to initialize firebase app
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Calculator from "./components/Calculator";
import About from "./components/About";
import BuyMeACoffee from "./components/BuyMeACoffee";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Provider } from 'react-redux'
import store from './store'
import ReactGA from 'react-ga';

console.log("Initializing GA");
ReactGA.initialize('UA-135150189-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
  	return (
  		<Provider store={store}>
	  		<Router>
	  			<div>
		  			<Navbar bg="dark" variant="dark" className="justify-content-between">
		  				<Navbar.Toggle aria-controls="basic-navbar-nav" />
		  				<Nav>
					      <Nav.Link href="/">Home</Nav.Link>
					      <Nav.Link href="/about">About</Nav.Link>
					    </Nav>
					    <BuyMeACoffee />
		  			</Navbar>
		  			<Container>
			  			<Switch>
				  			<Route exact path='/' component={Calculator} />
				  			<Route path='/about' component={About} />
			  			</Switch>
		  			</Container>
		  		</div>
	  		</Router>
	  	</Provider>
  		);
  }
}

export default App;