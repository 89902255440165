import {
	SET_SIMULATION_RESULT,
	SET_MORTGAGE_FORM_LOADING,
	SET_CURRENCY
} from "./actionTypes"

export const setSimulationResult = result => ({
	type: SET_SIMULATION_RESULT,
	payload: {
		totalInterest: result.totalInterest,
		totalOverpayment: result.totalOverpayment,
		totalLumpSums: result.totalLumpSums,
		monthlyRows: result.monthlyRows
	}
});

export const setCurrency = (currency) => ({
	type: SET_CURRENCY,
	payload: { currency }
})