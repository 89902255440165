import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Card } from "react-bootstrap";

class SimulationResult extends Component {
	getRows() {
		var rows = [];
		const { monthlyRows, currency } = this.props;
		monthlyRows.forEach((row) => {
			rows.push(
				<tr key={`month-${row.month}`}>
					<td>{row.month}</td>
					<td>{row.rate}%</td>
					<td>{row.interest}{currency}</td>
					<td>{row.overpayment + row.lump_sum}{currency}</td>
					<td>{row.remaining_principal}{currency}</td>
					<td>{row.monthly_installment}{currency}</td>
					<td>{row.paid_this_month}{currency}</td>
				</tr>
			)
		});
		return rows;
	}

	render() {
		const { totalInterest, totalOverpayment, totalLumpSums } = this.props;
		var rows = this.getRows();
		return (
			<div>
				<Card style={{ marginBottom: '20px' }}>
					<Card.Body>
						<h4>Simulation result</h4>
						<div><strong>Total interest: </strong><span>{totalInterest}</span></div>
						<div><strong>Total overpayment: </strong><span>{totalOverpayment}</span></div>
					</Card.Body>
				</Card>
				<Table striped bordered hover>
					<thead>
				    <tr>
				      <th>Month #</th>
				      <th>Interest Rate</th>
				      <th>Interest paid</th>
				      <th>Overpayment</th>
				      <th>Balance</th>
				      <th>Installment</th>
				      <th>Actual repayment</th>
				    </tr>
				  </thead>
				  <tbody>
				    {rows.length > 0 ? rows : (
							<tr>
								<td colSpan="7" style={{textAlign: 'center', color: '#888'}}>Nothing simulated yet. Fill the form on the left and click Simulate! :)</td>
							</tr>
						)}
				  </tbody>
				</Table>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const { totalInterest, totalOverpayment, totalLumpSums, monthlyRows } = state.simulationResult;
	const { currency } = state.config;
	return {
		totalInterest,
		totalOverpayment,
		totalLumpSums,
		monthlyRows,
		currency
	}
}

export default connect(mapStateToProps)(SimulationResult);