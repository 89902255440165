import * as firebase from "firebase";

var config = {
	apiKey: "AIzaSyBSJuknoeL_JwQ1zboF6N_dE9HdXwO9XX8",
	authDomain: "mortgagecalc-2e864.firebaseapp.com",
	databaseURL: "https://mortgagecalc-2e864.firebaseio.com",
	projectId: "mortgagecalc-2e864",
	storageBucket: "mortgagecalc-2e864.appspot.com",
	messagingSenderId: "955402307805"
};

firebase.initializeApp(config);