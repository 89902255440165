import React, { Component } from "react";
import CalculatorForm from "./CalculatorForm";
import SimulationResult from "./SimulationResult";
import { Container, Row, Col } from "react-bootstrap";

class Calculator extends Component {
	

	render() {
		return (
			<Container>
				<Row style={{ marginTop: '30px', marginBottom: '50px' }}>
					<Col>
						<h1>Mortgage Simulator</h1>
					</Col>
				</Row>
				<Row>
					<Col sm={4}>
						<CalculatorForm />
					</Col>
					<Col sm={8}>
						<SimulationResult />
					</Col>
				</Row>
			</Container>
		)
	}
}

export default Calculator;