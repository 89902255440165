import { SET_CURRENCY } from "../actionTypes";

const initialState = {
  currency: '€'
};

export default function(state = initialState, action) {
  switch (action.type){
    case SET_CURRENCY: {
      const { currency } = action.payload;
      return {
        ...state, currency
      }
    }
    default:
      return state
  }
};