import React, { Component } from "react";
import '../styles/buymeacoffee.css';

class BuyMeACoffee extends Component {
	render() {
		return (
			<div>
				<link href="https://fonts.googleapis.com/css?family=Cookie" rel="stylesheet" />
				<a className="bmc-button" target="_blank" href="https://www.buymeacoffee.com/sanpaolo">
					<img src="https://www.buymeacoffee.com/assets/img/BMC-btn-logo.svg" alt="Buy me a coffee" />
					<span style={{marginLeft:'5px'}}>Buy me a coffee</span>
				</a>
			</div>
		);
	}
}

export default BuyMeACoffee;